<template>
  <main class="main">
    <div class="nav">
      <h2>
        <span class="back" v-on:click="backToHome()">&lt;&nbsp;&nbsp;返回</span
        >测算记录
      </h2>
    </div>
    <div class="order">
      <div class="container">
        <div class="row orders">
          <div v-if="paidHistory.length === 0" class="col-md-6 emptyOrder">
            <div class="section-card">
              <h3 class="title" name="orderName">目前没有任何测算记录</h3>
              <hr class="separate" />
              <a
                class="btn btn-lg btn-result"
                name="orderResult"
                v-on:click="backToHome()"
                >前往测算！</a
              >
            </div>
          </div>

          <div
            v-for="(item, index) in paidHistory"
            :key="index"
            class="col-md-6 orderItem"
          >
            <div class="section-card">
              <h3 class="title" name="orderName">真命天子何时出现</h3>
              <hr class="separate" />
              <div class="item">
                <span class="subtitle">订单编号</span>
                <span class="content">{{ item.orderNo }}</span>
              </div>
              <div class="item">
                <span class="subtitle">建立时间</span>
                <span class="content">{{
                  item.quizTime
                    | moment('timezone', 'Etc/GMT-8', 'YYYY-MM-DD HH:mm:ss')
                }}</span>
              </div>
              <div class="item">
                <span class="subtitle">测算姓名</span>
                <span class="content"
                  >{{ item.name }} / {{ item.gender == 1 ? '男' : '女' }}</span
                >
              </div>
              <div
                class="btn btn-lg btn-result false"
                name="orderResult"
                @click="linkToResult(item.orderNo)"
              >
                {{ item.paid == 1 ? '查看结果' : '付款后自动开通' }}
              </div>
              <p style="color: 4a4a4a">
                {{ item.paid == 1 ? ' ' : '若付款资讯遗失请重新下单' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store'
import { SHI_CHEN } from '@/constants/shi-chen-list'
import { FORTUNE_GETTERS } from '@/store/modules/fortune-getters-types'

export default {
  name: 'HistoryView',
  async beforeRouteEnter(to, from, next) {
    // await check login
    if (!store.getters.isLogin) {
      console.log(to)
      console.log(location.href)
      let res = await store.dispatch('login', {
        ...to.query,
        path: to.path,
        channel: store.state.fortune.channel,
      })
      console.log('history then')
      console.log(res)
      if (res) {
        next()
      }
    } else {
      next()
    }
  },
  mounted: function () {
    console.log('mount')
    document.documentElement.scrollTop = 0
  },
  methods: {
    backToHome: function () {
      this.$router.push({ name: 'Home' })
    },
    linkToService: function () {
      this.$router.push({ name: 'Service' })
    },
    linkToResult: function (orderNo) {
      this.$router.push({ name: 'Result', params: { orderNo } })
    },
    getLunarHour: function (hour) {
      if (hour !== 0 && hour % 2 === 0) {
        hour--
      }
      return SHI_CHEN[hour]
    },
  },
  computed: {
    ...mapState({
      history: (state) => state.fortune.history,
      isLogin: (state) => state.isLogin,
    }),
    ...mapGetters('fortune', {
      isNotEmpty: FORTUNE_GETTERS.IS_NOT_EMPTY,
      isLoading: FORTUNE_GETTERS.IS_LOADING,
    }),
    paidHistory: function () {
      return this.history.filter(function (item) {
        return item.paid === true
      })
    },
  },
}
</script>

<style src="@/assets/css/order.css" scoped></style>
<style scoped>
p {
  margin-bottom: 0;
}
.nav {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
}
.nav h2 {
  line-height: inherit;
  font-size: 1.5em;
  font-weight: bold;
}
.back {
  position: absolute;
  left: 3%;
  cursor: pointer;
}

.main {
  background-color: #f3efed;
  min-height: 100vh;
  font-size: 14px;
}
.container {
  width: 100%;
}
.separate {
  opacity: 1;
}
.orderItem {
  padding-left: 15px;
  padding-right: 15px;
}
.btn-result {
  color: #fff;
  background-color: #ef3900;
  border-color: #ef3900;
  border-radius: 10px;
}

.btn-result:hover {
  color: #fff;
  background-color: #f15726;
  border-color: #f14d1a;
}
.btn-lg {
  padding: 8px 0;
  height: 44px;
  line-height: 28px;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
}
</style>
